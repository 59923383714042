.main {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    flex-direction: column;
    font-family: 'Roboto';
}

.main img {
    width: 60px;
}

.main h1 {
    font-weight: 900;
}

.main p {
    font-weight: 500;
}